import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaStar } from 'react-icons/fa';

export const Reviews = () => {

  const reviews = [
    { id: 1, name: "Ольга", text: "Всё отлично. Смело могу рекомендовать мастера. Дистанционно определил проблему, оперативно приехал, хотя территориально находимся не очень близко от СПБ, всё исправил. Дополнительно провёл диагностику всей системы, исправил небольшие неточности в нашей сборке. Дополнительно денежные средства не накрутил." },
    { id: 2, name: "Андрей", text: 'К назначенному времени группа прибыла в нужном составе с нужными комплектами инструментария. Грамотно проводили диагностику неисправности кондиционера, поэтапно и не пугали одномоментно, что "все плохо". Пришлось вывешиваться с крыши, но и здесь не было сюрпризов. Как итог установили причину-плохая вальцовка гаек газовой магистрали и как следствие хладоген вытек. С двумя перекурами и за 2.5 - 3 часа все сделали. Кондиционер холодит) Далее еще 4 дня были на связи со со мной для проверки работоспособности моего охладителя. В общем и целом могу рекомендовать этого исполнителя; руководствуется не жаждой наживы, а разумным подходом в решении проблемы.' },
    { id: 3, name: "Вячеслав", text: "Быстро и чётко. Видно, что профессионал своего дела!" },
    { id: 4, name: "Александр", text: "Не охлаждал помещение кондиционер. Установил и устранил неисправность, обьяснил, заправил фреоном. Все быстро, чисто, аккуратно. Меня все устроило." },
    { id: 5, name: "Дмитрий", text: "Спасибо за работу. Быстро откликнулся, оценил и так же быстро сделал всё по нашим желаниям." },
    { id: 6, name: "Павел", text: "Хочу выразить благодарность мастеру за работу! Всё быстро, оперативно, аккуратно. Моментально ответил на заказ и предложил удобное ближайшее время. Буквально за 4 часа мы получили работающий кондиционер. Дает рекомендации по эксплуатации. Максимально чисто после выполненной работы. Мастера рекомендую." },
    { id: 7, name: "Алиса", text: "Дмитрий приехал быстро, решил проблему. Спасибо, буду обращаться ещё" },
    { id: 8, name: "Ирина", text: "Всё отлично ) рекомендую )" },
    { id: 9, name: "Ольга", text: "Мастер приехал во время, со всеми необходимыми инструментами. Больше запланированного времени пришлось потратить на бурение стены из за выхода на арматуру внутри стены. Претензий к работе нет, сделано все нормально.Не понравился вид тянущегося шланга до внешнего блока вдоль стены на лоджии. Придется думать, как его замаскировать. Но по другому, видимо, было не сделать. Стоимость работы как и договаривались 14600" },
    { id: 10, name: "Андрей", text: "Всё супер, всё рассказал, показал, посоветовал, где лучше установить" },
    { id: 11, name: "Ольга", text: "Дмитрий взялся за непростую задачу: демонтировал наружный блок сплит- системы, перенёс его на лоджию, перевесил внутренний блок и много чего ещё. Работа была сделана качественно, аккуратно. Пунктуальный, приехал точно ко времени, оплата произведена как и договаривались. Приятно иметь дело с таким специалистом. Огромное спасибо ему! Рекомендую всем" },
    { id: 12, name: "Вадим", text: "Специалист высокого профессионального уровня! Всё сделал качественно, чисто, быстро, недорого. Избавил нас от проблемы, которая 12 лет никем не могла быть исправлена. Рекомендую специалиста 100%. Спасибо и удачи!" },
    { id: 13, name: "Анна", text: "Всё сделали, несмотря на сложности." },
    { id: 14, name: "Станислав", text: "Лучший специалист и невероятно ответственный человек! Сложнейший объект из 13 кондиционеров в центре Петербурга." },
    { id: 15, name: "Вадим", text: "Всё прекрасно, спасибо" },
    { id: 16, name: "Ирина", text: "Работа выполнена быстро и профессионально. Договорились, приехал вовремя, быстро установил и проверил кондиционер." },
  ];
  
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animateDirection, setAnimateDirection] = useState("");

  const nextTestimonial = () => {
    setAnimateDirection("right");
    setTimeout(() => {
      setCurrentIndex((currentIndex + 1) % reviews.length);
      setAnimateDirection("");
    }, 500);
  };

  const prevTestimonial = () => {
    setAnimateDirection("left");
    setTimeout(() => {
      setCurrentIndex(
        currentIndex === 0 ? reviews.length - 1 : currentIndex - 1
      );
      setAnimateDirection("");
    }, 500);
  };

  return (

    <div className='flex flex-col items-center w-full p-6'>

  <h1 className="mt-[35px] text-[32px] md:text-[40px] font-bold text-center">
    Отзывы наших клиентов:
  </h1>

  <div className="mt-[30px] relative w-full md:w-2/4 p-4 bg-gray-100 rounded-lg shadow-md overflow-hidden">
    <div className="py-4 flex items-center justify-center">
      <div
        className={`flex flex-col items-center justify-center transition-all duration-500 ease-in-out ${
          animateDirection === "right" ? "opacity-0 -translate-x-10" : ""
        } ${animateDirection === "left" ? "opacity-0 translate-x-10" : ""}
        ${animateDirection === "" ? "opacity-100 translate-x-0" : ""}`}
      >
        <p className="text-center text-gray-800 py-4 w-[90%]">{reviews[currentIndex].text}</p>
        <p className="text-center text-gray-400">{reviews[currentIndex].name}</p>
        <div className='flex flex-row space-x-1 mt-2'>
          <FaStar color="gold" size={24} />
          <FaStar color="gold" size={24} />
          <FaStar color="gold" size={24} />
          <FaStar color="gold" size={24} />
          <FaStar color="gold" size={24} />
        </div>
      </div>
    </div>
    <div className="flex justify-between items-center mt-4">
      <button
        onClick={prevTestimonial}
        className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition duration-200"
      >
        <FaArrowLeft />
      </button>
      <button
        onClick={nextTestimonial}
        className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition duration-200"
      >
        <FaArrowRight />
      </button>
    </div>
  </div>

</div>

  );
};
