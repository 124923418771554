import React from "react";

import Example1 from "../../img/Example1.jpg";
import Example2 from "../../img/Example2.jpg";
import Example3 from "../../img/Example3.jpg";
import Example4 from "../../img/Example4.jpg";
import Example5 from "../../img/Example5.jpg";
import Example6 from "../../img/Example6.jpg";
import Example7 from "../../img/Example7.jpg";

export const Subscriptions = () => {
  return (
    <section
      id="subscriptions"
      className="bg-[#F4F6F9] w-full flex flex-col items-center mt-16 py-12 px-4"
    >
      <h1 className="text-3xl md:text-4xl font-bold text-center">
        Наши работы:
      </h1>

      <div className="mt-12 w-full max-w-6xl mx-auto flex flex-col lg:flex-row lg:space-x-8 space-y-8 lg:space-y-0">
  {/* Большое изображение */}
  <div className="w-full lg:w-1/2 rounded-lg overflow-hidden flex">
    <img
      src={Example2}
      alt="Описание работы 1"
      className="w-full h-auto object-cover"
      loading="lazy"
    />
  </div>

  {/* Колонка с маленькими изображениями */}
  <div className="w-full lg:w-1/2 grid grid-cols-2 gap-4">
          <div className="h-40 md:h-48 lg:h-40 rounded-lg overflow-hidden">
            <img
              src={Example1}
              alt="Описание работы 2"
              className="w-full h-full object-cover"
              loading="lazy"
            />
          </div>
          <div className="h-40 md:h-48 lg:h-40 rounded-lg overflow-hidden">
            <img
              src={Example3}
              alt="Описание работы 3"
              className="w-full h-full object-cover"
              loading="lazy"
            />
          </div>
          <div className="h-40 md:h-48 lg:h-40 rounded-lg overflow-hidden">
            <img
              src={Example4}
              alt="Описание работы 4"
              className="w-full h-full object-cover"
              loading="lazy"
            />
          </div>
          <div className="h-40 md:h-48 lg:h-40 rounded-lg overflow-hidden">
            <img
              src={Example5}
              alt="Описание работы 5"
              className="w-full h-full object-cover"
              loading="lazy"
            />
          </div>
          <div className="h-40 md:h-48 lg:h-40 rounded-lg overflow-hidden">
            <img
              src={Example6}
              alt="Описание работы 6"
              className="w-full h-full object-cover"
              loading="lazy"
            />
          </div>
          <div className="h-40 md:h-48 lg:h-40 rounded-lg overflow-hidden">
            <img
              src={Example7}
              alt="Описание работы 7"
              className="w-full h-full object-cover"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
