import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { Wrapper } from "../../components/wrapper";
import { Header } from "../../header";
import { MainSection } from "../../pages/main/main-section";
import { Steps } from "../../pages/main/steps";
import { Reviews } from "../../pages/main/reviews";
import { Tips } from "../../pages/main/tips";
import { FAQ } from "../../pages/main/faq";
import { Footer } from "../../components/footer";
import { Subscriptions } from "../subscriptions/subscriptions";
import { Systems } from "./systems";
import { Contacts } from "./contacts";
import { Files } from "./files";

export const MainPage = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <Wrapper>
      <Header />
      <div data-aos="fade-up">
        <MainSection />
      </div>
      <div data-aos="fade-right">
        <Steps />
      </div>
      <div data-aos="fade-left">
        <Systems />
      </div>
      <div data-aos="fade-up">
        <Tips />
      </div>
      <div data-aos="zoom-in">
        <Subscriptions />
      </div>
      <div data-aos="fade-up">
        <Reviews />
      </div>
      <div data-aos="fade-left">
        <Files />
      </div>
      <div data-aos="fade-up">
        <FAQ />
      </div>
      <div data-aos="fade-right">
        <Contacts />
      </div>
      <Footer />
    </Wrapper>
  );
};
