import React from "react";

export const FAQ = () => {
  const tableData = [
    ["07-09(2.0-3.0)", "14 900", "2 000", "по договорённости"],
    ["12 (3.1-4.5)", "15 900", "2 000", "по договорённости"],
    ["18 (4.6-5.0)", "18 600", "2 500", "по договорённости"],
    ["24 (5.1-7.0)", "22 800", "3 000", "по договорённости"],
    ["36 (7.1-10.0)", "28 800", "3 000", "по договорённости"],
  ];

  const secondTableData = [
    ["2.0-5.5", "7 500", "5 000"],
    ["5.6-10.0", "8 500", "5 000"],
    ["10.1-16.0", "10 000", "5 000"],
  ];

  const included = [
    "Бурение отверстия",
    "3 метра межблочных коммуникаций (медный фреонопровод, изоляция, кабель)",
    "0,5 м кабель-канала (55х74)",
    "Монтаж внутреннего блока",
    "Монтаж наружного блока без применения высотных работ",
    "Монтаж дренажной системы (самотёком 3 метра шланга)",
    "Прокладка электропитания (до 3-х метров)",
    "Вакуумирование",
    "Герметизация отверстия",
    "Весь крепёж",
    "Пуско-наладочные работы",
    "Инструктаж пользователя",
  ];

  const secondIncluded = [
    "Визуальный осмотр",
    "Чистка фильтров",
    "Чистка испарителя внутреннего блока парогенератором или мойкой низкого давления",
    "Контроль рабочего давления",
    "Дозаправка фреоном, при необходимости (не более 150 гр)",
    "Дезинфекция испарителя",
    "Чистка конденсатора наружного блока",
    "Проверка дренажной системы",
  ];

  return (
    <section id="faq" className="w-full flex flex-col items-center p-5 md:p-10">
      <div className="max-w-screen-lg w-full mx-auto">
        <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-center">
          Прайс-лист
        </h1>

        {/* Стандартный монтаж */}
        <div id="cond" className="mt-8">
          <h2 className="text-xl md:text-2xl font-semibold text-black">
            Что входит в стандартный монтаж:
          </h2>

          <div className="overflow-x-auto mt-4">
            <table className="min-w-full border-collapse border border-gray-300 text-sm md:text-base">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 px-4 py-3">BTU (кВт)</th>
                  <th className="border border-gray-300 px-4 py-3">Стандартный монтаж, руб</th>
                  <th className="border border-gray-300 px-4 py-3">Доп. трасса, руб/м</th>
                  <th className="border border-gray-300 px-4 py-3">Высотные работы, руб</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, rowIndex) => (
                  <tr key={rowIndex} className="even:bg-gray-50">
                    {row.map((col, colIndex) => (
                      <td key={colIndex} className="border border-gray-300 px-4 py-2 text-left">
                        {col}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-6 space-y-3">
            {included.map((item, index) => (
              <div key={index} className="flex items-center space-x-3">
                <div className="flex-shrink-0 w-4 h-4 flex justify-center items-center">
                  <span className="block w-3 h-3 bg-blue-700 rounded-full"></span>
                </div>
                <p className="text-left">{item}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Техническое обслуживание */}
        <div id="service" className="mt-8">
          <h2 className="text-xl md:text-2xl font-semibold text-black">
            Что входит в техническое обслуживание:
          </h2>

          <div className="overflow-x-auto mt-4">
            <table className="min-w-full border-collapse border border-gray-300 text-sm md:text-base">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 px-4 py-3">Хладопроизводимость кВт</th>
                  <th className="border border-gray-300 px-4 py-3">ТО</th>
                  <th className="border border-gray-300 px-4 py-3">Высотные работы, руб</th>
                </tr>
              </thead>
              <tbody>
                {secondTableData.map((row, rowIndex) => (
                  <tr key={rowIndex} className="even:bg-gray-50">
                    {row.map((col, colIndex) => (
                      <td key={colIndex} className="border border-gray-300 px-4 py-2 text-left">
                        {col}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-6 space-y-3">
            {secondIncluded.map((item, index) => (
              <div key={index} className="flex items-center space-x-3">
                <div className="flex-shrink-0 w-4 h-4 flex justify-center items-center">
                  <span className="block w-3 h-3 bg-blue-700 rounded-full"></span>
                </div>
                <p className="text-left">{item}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
