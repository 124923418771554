import React from "react";

import Image from "../../img/install.jpg";

export const Contacts = () => {
  return (
    <section 
  id="contacts"
  className="w-full flex flex-col items-center mt-[30px] relative">

  <div className="flex flex-row w-full h-[300px] md:h-[500px]">

    <div className="w-full md:w-1/2 bg-gray-300 bg-cover bg-center relative" style={{
      backgroundImage: `url(${Image})`,
    }}>
      {/* Затемнение фона на мобильных устройствах */}
      <div className="absolute inset-0 bg-black opacity-50 md:opacity-0"></div>
      
      {/* Центрированный белый текст на маленьких экранах */}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white px-4 md:hidden">
        <h1 className="text-[24px] font-bold">
          Осталось только позвонить нам.
        </h1>
        <p className="mt-[20px] text-[20px] text-white font-bold">
        Наши контакты:
      </p>

      <h2 className="text-[35px] text-white font-bold mb-[10px] cursor-pointer"
          onClick={() => window.location.href = 'tel:+79219260925'}>
        +7 (921) 926-09-25
      </h2>
      <h2 className="text-[20px] text-gray-200 font-bold mb-[10px]">
      airobriz.spb@mail.ru
      </h2>
      </div>
    </div>

    <div className="hidden md:flex w-1/2 flex-col p-10 h-full justify-center">

      <h1 className="text-[40px] text-black font-bold leading-[44px] w-5/6 md:text-black">
        Осталось только позвонить нам
      </h1>

      <p className="mt-[12px] text-[20px] text-gray-600 font-extralight">
        airoБРИЗ.СПБ
      </p>

      <p className="mt-[40px] text-[20px] text-black font-bold">
        Наши контакты:
      </p>

      <h2 className="text-[35px] text-blue-700 font-bold mb-[10px] cursor-pointer"
          onClick={() => window.location.href = 'tel:+79219260925'}>
        +7 (921) 926-09-25
      </h2>

      <h2 className="text-[20px] text-gray-700 font-bold mb-[10px]">
      airobriz.spb@mail.ru
      </h2>

    </div>

  </div>
  
</section>

  );
};
