import React, { useState } from 'react';

import Word from "../../img/word.png";

import Preview1 from "../../img/pr1.jpg";
import Preview2 from "../../img/pr2.jpg";
import Preview3 from "../../img/pr3.jpg";
import Preview4 from "../../img/pr4.webp";
import Preview5 from "../../img/pr5.jpg";
import Preview6 from "../../img/pr6.jpg";

import file1 from "../../files/инвенторы.docx";
import file2 from "../../files/кс.docx";
import file3 from "../../files/пп.docx";

export const Files = () => {

    const downloadFile = (doc, name) => {
        const link = document.createElement('a');
        link.href = doc;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

  return (
    <div id="files" className='flex flex-col justify-center items-center w-full space-y-12 p-6'>
      
      <div className='flex flex-wrap justify-center gap-8'>
        {/* Card 1 */}
        <div className="w-full sm:w-[370px] h-[450px] bg-gray-200 relative rounded-lg shadow-lg">
          <h1 className="text-left text-black font-semibold text-[25px] leading-7 mb-4 p-7">
            Настенные сплит-системы постоянной производительности
          </h1>
          <div 
            className="mt-[30px] w-full h-[250px] bg-cover bg-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            style={{ backgroundImage: `url(${Preview1})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
          >
          </div>
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-4">
            <button className="bg-blue-500 text-white px-12 py-2 rounded-lg shadow-md hover:bg-blue-600 whitespace-nowrap"
              onClick={() => downloadFile(file3, 'пп.docx')}>
              Прайс-оборудование
            </button>
          </div>
        </div>

        {/* Card 2 */}
        <div className="w-full sm:w-[370px] h-[450px] bg-gray-200 relative rounded-lg shadow-lg">
          <h1 className="text-left text-black font-semibold text-[25px] leading-7 mt-3 p-7">
            Настенные сплит-системы инверторные
          </h1>
          <div 
            className="mt-[30px] w-full h-[250px] bg-cover bg-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            style={{ backgroundImage: `url(${Preview2})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
          >
          </div>
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-4">
            <button className="bg-blue-500 text-white px-12 py-2 rounded-lg shadow-md hover:bg-blue-600 whitespace-nowrap"
              onClick={() => downloadFile(file1, 'инвенторы.docx')}>
              Прайс-оборудование
            </button>
          </div>
        </div>

        {/* Card 3 */}
        <div className="w-full sm:w-[370px] h-[450px] bg-gray-200 relative rounded-lg shadow-lg">
          <h1 className="text-left text-black font-semibold text-[25px] leading-7 mt-3 p-7">
            Кассетные сплит-системы
          </h1>
          <div 
            className="mt-[30px] w-full h-[250px] bg-cover bg-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            style={{ backgroundImage: `url(${Preview3})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
          >
          </div>
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-4">
            <button className="bg-blue-500 text-white px-12 py-2 rounded-lg shadow-md hover:bg-blue-600 whitespace-nowrap"
              onClick={() => downloadFile(file2, 'кс.docx')}>
              Прайс-оборудование
            </button>
          </div>
        </div>

        {/* Card 4 */}
        <div className="w-full sm:w-[370px] h-[450px] bg-gray-200 relative rounded-lg shadow-lg">
          <h1 className="text-left text-black font-semibold text-[25px] leading-7 mt-3 p-7">
            Инверторные мультисплит-системы
          </h1>
          <div 
            className="mt-[30px] w-full h-[250px] bg-cover bg-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            style={{ backgroundImage: `url(${Preview4})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
          >
          </div>
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-4">
            <p className="text-gray-700 font-bold px-12 py-2 rounded-lg whitespace-nowrap">
              по договорённости
            </p>
          </div>
        </div>

        {/* Card 5 */}
        <div className="w-full sm:w-[370px] h-[450px] bg-gray-200 relative rounded-lg shadow-lg">
          <h1 className="text-left text-black font-semibold text-[25px] leading-7 mt-3 p-7">
            Напольно-потолочные сплит-системы
          </h1>
          <div 
            className="mt-[30px] w-full h-[250px] bg-cover bg-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            style={{ backgroundImage: `url(${Preview5})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
          >
          </div>
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-4">
            <p className="text-gray-700 font-bold px-12 py-2 rounded-lg whitespace-nowrap">
              по договорённости
            </p>
          </div>
        </div>

        {/* Card 6 */}
        <div className="w-full sm:w-[370px] h-[450px] bg-gray-200 relative rounded-lg shadow-lg">
          <h1 className="text-left text-black font-semibold text-[25px] leading-7 mt-3 p-7">
            Канальные сплит-системы
          </h1>
          <div 
            className="mt-[30px] w-full h-[250px] bg-cover bg-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            style={{ backgroundImage: `url(${Preview6})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
          >
          </div>
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-4">
            <p className="text-gray-700 font-bold px-12 py-2 rounded-lg whitespace-nowrap">
              по договорённости
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
