import React from "react";

import Photo1 from "../../img/tip1.jpg";
import Photo2 from "../../img/tip2.jpg";
import Photo3 from "../../img/tip3.jpg";
import Photo4 from "../../img/tip4.jpg";

export const Tips = () => {
  const scrollTo = (section) => {
    const element = document.getElementById(section);
    if (element) {
      const headerOffset = 85;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  return (
    <section id="whatis" className="w-full flex flex-col mt-[45px] items-center">

      <h1 className="text-center text-[35px] font-bold w-[70%] leading-[38px]">
      Заказывая монтаж климатического оборудования в нашей компании вы получаете:
      </h1>

      <div className="flex flex-col xl:flex-row mt-[50px] space-y-8 xl:space-y-0 xl:space-x-8">

        <div className="w-[270px] h-[457px] bg-gray-500 flex flex-col items-center rounded-3xl bg-cover bg-center relative" style={{
            backgroundImage: `url(${Photo1})`,
          }}>

        <div className="absolute inset-0 bg-black opacity-70 rounded-3xl"></div>

        <div className="h-[1/2] flex justify-start w-full z-10">
          <div className="relative w-full h-[70px] bg-blue-700 text-white text-center whitespace-pre-line font-bold text-[20px] items-center justify-center flex leading-6">
            {`Бесплатная\nконсультация`}
          </div>
        </div>
          
        <div className="h-full flex justify-center items-center z-10">
          <p className="text-center w-[80%] text-[25px] font-normal text-white leading-[30px]">
          Оставьте контакт для связи с Вами или позвоните нам напрямую.
          </p>
        </div>

        </div>

        <div className="w-[270px] h-[457px] bg-gray-500 flex flex-col items-center rounded-3xl bg-cover bg-center relative" style={{
            backgroundImage: `url(${Photo2})`,
          }}>

<div className="absolute inset-0 bg-black opacity-70 rounded-3xl"></div>

        <div className="h-[1/2] flex justify-start w-full z-10">
          <div className="relative w-full h-[70px] bg-blue-700 text-white text-center whitespace-pre-line font-bold text-[20px] items-center justify-center flex leading-6">
            {`Бесплатный\nрасчёт`}
          </div>
        </div>
          
        <div className="h-full flex justify-center items-center z-10">
          <p className="text-center w-[80%] text-[25px] font-normal text-white leading-[30px]">
          Считаем предварительную стоимость работ, также можем предоставить смету «под ключ» без выезда специалиста.
          </p>
        </div>

        </div>

        <div className="w-[270px] h-[457px] bg-gray-500 flex flex-col items-center rounded-3xl bg-cover bg-center relative" style={{
            backgroundImage: `url(${Photo3})`,
          }}>

<div className="absolute inset-0 bg-black opacity-70 rounded-3xl"></div>

        <div className="h-[1/2] flex justify-start w-full z-10">
          <div className="relative w-full h-[70px] bg-blue-700 text-white text-center whitespace-pre-line font-bold text-[20px] items-center justify-center flex leading-6">
            {`Бесплатный\nвыезд`}
          </div>
        </div>
          
        <div className="h-full flex justify-center items-center z-10">
          <p className="text-center w-[80%] text-[25px] font-normal text-white leading-[30px]">
          Cпециалиста при заключении договора на объекте. В ином случае – 1.000 руб (в пределах Санкт-Петербурга).
          </p>
        </div>

        </div>

        <div className="w-[270px] h-[457px] bg-gray-500 flex flex-col items-center rounded-3xl bg-cover bg-center relative" style={{
            backgroundImage: `url(${Photo4})`,
          }}>

<div className="absolute inset-0 bg-black opacity-70 rounded-3xl"></div>

        <div className="h-[1/2] flex justify-start w-full z-10">
          <div className="relative w-full h-[70px] bg-blue-700 text-white text-center whitespace-pre-line font-bold text-[20px] items-center justify-center flex leading-6">
            {`Бесплатная\nдоставка`}
          </div>
        </div>
          
        <div className="h-full flex justify-center items-center z-10">
          <p className="text-center w-[80%] text-[25px] font-normal text-white leading-[30px]">
          Оборудования в дату монтажа (в пределах Санкт-Петербурга).
          </p>
        </div>

        </div>

      </div>

    </section>
  );
};
