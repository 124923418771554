import React from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom';

import { MainPage } from './pages/main/main';
import { NotFoundPage } from './pages/not-found/main';
// import { SubscriptionsPage } from './pages/subscriptions/main';
// import { WhatIsPage } from './pages/whatis/main';
// import { HelpPage } from './pages/help/main';
// import { DownloadPage } from './pages/download/main';

const Routes = () => {
  const routes = useRoutes([
    { path: "/", element: <MainPage /> },
    // { path: "/subscriptions", element: <SubscriptionsPage /> },
    // { path: "/whatis", element: <WhatIsPage /> },
    // { path: "/help", element: <HelpPage /> },
    // { path: "/download", element: <DownloadPage /> },
    { path: "*", element: <MainPage /> }
  ]);

  return routes;
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default App