import React from "react";

import DiscotechLogo from "../../img/discotechlogo.png";

const scrollTo = (section) => {
  const element = document.getElementById(section);
  if (element) {
    const headerOffset = 85;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }
};

export const Footer = () => {
  return (
    <footer>
    <a href="https://vk.com/discotech" className="bg-gradient-to-r from-[#001F30] via-[#3b3b8d] to-[#6e15a3] flex flex-col-reverse p-3 lg:space-y-0 lg:flex-row items-center justify-center lg:space-x-14">
      <div className="text-white text-center text-[12px]">
      <p>© 2024 AiroБРИЗ.СПБ. Все права защищены. Сайт разработан проектом discotech</p>
      </div>
    </a>
  </footer>
  );
};
