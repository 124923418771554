import React from "react";

import Image from "../../components/Image";

import BackgroundImage from "../../img/bg.jpg";
import ShieldImage from '../../img/shield.svg'
import SpannerImage from '../../img/spanner.svg'
import RubleImage from '../../img/ruble.svg'

export const MainSection = () => {

  const scrollTo = (section) => {
    const element = document.getElementById(section);
    if (element) {
      const headerOffset = 85;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  return (
    <section 
      id="main-section" 
      className="flex flex-col items-center justify-center min-h-screen relative overflow-hidden"
    >
      <img 
        src={BackgroundImage}
        alt="bg" 
        className="absolute inset-0 object-cover w-full h-full"
      />
      <div className="absolute inset-0 bg-black opacity-70"></div>
      
      <div className="relative z-10 text-center text-white w-full flex flex-col justify-center items-center">
        <h1 className="text-3xl lg:text-6xl font-bold mt-16 lg:mt-6 whitespace-pre-line">
          {`Продажа и монтаж\nсистем кондиционирования`}
        </h1>

        <div className="mt-16 flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
          {[{
            img: ShieldImage,
            title: "Гарантия",
            description: "От 2-х лет"
          }, {
            img: SpannerImage,
            title: "Монтаж",
            description: "От 14.990руб."
          }, {
            img: RubleImage,
            title: "Оплата",
            description: "При получении оборудования, выполненного монтажа"
          }].map((item, index) => (
            <div 
              key={index}
              className="w-[360px] h-[250px] rounded-2xl opacity-70 bg-white flex flex-col items-center justify-center space-y-2"
            >
              <img 
                src={item.img}
                alt={item.title.toLowerCase()}
                className="h-16 w-16"
              />
              <h1 className="font-bold text-[40px] text-black">
                {item.title}
              </h1>
              <p className={`font-normal text-[15px] w-3/4 text-black`}>
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
