import React from "react";
import SetupImage from '../../img/ustkond.webp';
import RoomImage from '../../img/whiteroom.webp';

export const Steps = () => {

  const scrollTo = (section) => {
    const element = document.getElementById(section);
    if (element) {
      const headerOffset = 85;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };
  
  return (
    <section className="bg-[#242A34] w-full flex flex-col lg:flex-row py-6 lg:py-[30px] justify-center space-y-6 lg:space-y-0 lg:space-x-6 items-center">

      <div className="w-full max-w-[540px] h-[280px] lg:h-[380px] bg-black flex flex-col justify-between relative rounded-lg overflow-hidden">
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center z-0"
          style={{
            backgroundImage: `url(${RoomImage})`,
          }}
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-60 z-0"></div>

        <div className="flex flex-col items-center relative z-10 px-4">
          <h1 className="text-white font-bold text-2xl lg:text-[40px] text-center mt-8 lg:mt-[60px]">
            Кондиционирование
          </h1>
          <div className="bg-blue-500 h-[3px] w-[20%] mt-4 lg:mt-[25px]"></div>
          <p className="text-white font-light text-sm lg:text-[17px] mt-4 lg:mt-[30px] text-center w-full max-w-[75%]">
            Подбор оборудования, монтаж и проектирование систем кондиционирования для дома и офиса и промышленного помещения
          </p>
        </div>

        <button 
          className="bg-white h-10 w-[70%] lg:w-[50%] mb-6 lg:mb-[50px] self-center relative z-10 rounded-md"
          onClick={() => scrollTo("cond")}>
          Подробнее
        </button>
      </div>
      
      <div className="w-full max-w-[540px] h-[280px] lg:h-[380px] bg-black flex flex-col justify-between relative rounded-lg overflow-hidden">
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center z-0"
          style={{
            backgroundImage: `url(${SetupImage})`,
          }}
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-60 z-0"></div>

        <div className="flex flex-col items-center relative z-10 px-4">
          <h1 className="text-white font-bold text-2xl lg:text-[40px] text-center mt-8 lg:mt-[60px]">
            Сервис
          </h1>
          <div className="bg-blue-500 h-[3px] w-[20%] mt-4 lg:mt-[25px]"></div>
          <p className="text-white font-light text-sm lg:text-[17px] mt-4 lg:mt-[30px] text-center w-full max-w-[75%]">
            Техническое обслуживание кондиционеров
          </p>
        </div>

        <button 
          className="bg-white h-10 w-[70%] lg:w-[50%] mb-6 lg:mb-[50px] self-center relative z-10 rounded-md"
          onClick={() => scrollTo("service")}>
          Подробнее
        </button>
      </div>

    </section>
  );
};
