import React from 'react'

class Image extends React.Component {

  render() { 
    return (
        <img 
        src={this.props.image} 
        className={`${this.props.isClickable && 'transform transition duration-300 ease-in-out hover:scale-105 hover:brightness-150'} ${this.props.name}`}
        alt={this.props.alt || "Описание"}
        height={(this.props.size ? this.props.height / 100 * this.props.size : this.props.height)}
        width={(this.props.size ? this.props.width / 100 * this.props.size : this.props.width)}
        />
      )
  }
}

export default Image