import React from "react";

import Logo from "../img/abLogoLong.png";
import LogoSmall from "../img/abLogoCropped.png";
import Image from "../components/Image";

import { NavItem } from "../components/nav-item";

export const Header = ({ transparent = false }) => {

  const scrollTo = (section) => {
    const element = document.getElementById(section);
    if (element) {
      const headerOffset = 85;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  return (
    <header className={`bg-white py-3 top-0 left-0 w-full z-50`}>
  <div className="flex justify-center">
    <div className="
    max-w-[350px] sm:max-w-[550px] md:max-w-[700px] lg:max-w-[950px] xl:max-w-[1124px] 
    w-full">
      <div className="flex items-center justify-between">
      <a className="flex lg:hidden" href="/">
          <Image image={LogoSmall} width="194" height="194" size="50" />
      </a>
      <a className="hidden lg:flex" href="/">
          <Image image={Logo} width="194" height="194" size="100" />
      </a>

        <div className="hidden lg:flex lg:flex-row items-center space-x-12 xl:space-x-16">
          <NavItem
            text="О нас"
            color={transparent ? "text-white" : "text-almost-black"}
            className="cursor-pointer"
            onClick={() => scrollTo("download")}
          />
          <NavItem
            text="Каталог"
            color={transparent ? "text-white" : "text-almost-black"}
            className="cursor-pointer"
            onClick={() => scrollTo("files")}
          />
          <NavItem
            text="Прайс-лист"
            color={transparent ? "text-white" : "text-almost-black"}
            className="cursor-pointer"
            onClick={() => scrollTo("faq")}
          />
          <NavItem
            text="Контакты"
            color={transparent ? "text-white" : "text-almost-black"}
            className="cursor-pointer"
            onClick={() => scrollTo("contacts")}
          />
        </div>

        <div className="flex flex-row">

          <div className="flex flex-col items-center">

            <a className="text-[15px] font-bold">
              +7 (921) 926-09-25
            </a>
            <a className="text-[15px] font-bold">
            airobriz.spb@mail.ru
            </a>
            <a className="text-[15px] font-bold text-sky-500">
              Санкт-Петербург и ЛО
            </a>

          </div>

        </div>
      </div>
    </div>
  </div>
</header>


  );
};
