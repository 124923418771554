import React, { useState } from 'react';
import axios from 'axios';

export const Systems = () => {
  const [response, setResponse] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedRoomType, setSelectedRoomType] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = new URLSearchParams({
      name: response,
      phone,
      area: selectedArea,
      roomType: selectedRoomType
    });
    
    fetch(
      "https://script.google.com/macros/s/AKfycbz-9lfWzLTrRUvBHylXq7Ywv3aOAOfrNgJjDpyge8OMMs5D-NddP0o_TDSrZxMUb5pK/exec",
      {
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSubmitted(true);
        setResponse('');
        setPhone('');
        setSelectedArea('');
        setSelectedRoomType('');
      })

      .catch((error) => {
        console.log(error);
      });
    

  };

  return (
    <section id="download" className="w-full flex flex-col items-center mt-10 px-4 lg:px-0">
      <div className="w-full max-w-5xl rounded-xl bg-gray-100 flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 bg-gray-200 p-6 lg:p-10 space-y-4">
          <h1 className="font-bold text-2xl lg:text-[40px] leading-snug">
            Климатическая компания
          </h1>
          <p className="font-normal text-sm lg:text-[18px] leading-relaxed">
            Наша команда состоит из высококвалифицированных специалистов с многолетним опытом работы в сфере климата. Мы оказываем полный спектр услуг как для частных лиц, так и для коммерческих компаний. Нас выбирают, потому что:
          </p>
          {/* Пояснения, почему вас выбирают */}
        </div>

        <div className="w-full lg:w-1/2 p-6 lg:p-10">
          <form onSubmit={handleSubmit}>
            <label htmlFor="responseName" className="block mb-2 text-gray-700">
              Ваше имя:
            </label>
            <textarea
              value={response}
              onChange={(e) => setResponse(e.target.value)}
              placeholder="Введите ваше имя..."
              className="w-full p-2 border border-gray-300 rounded mb-4"
              rows="1"
              required
              style={{ resize: 'none' }}
            />

            <label htmlFor="phoneOption" className="block mb-2 text-gray-700">
              Ваш телефон:
            </label>
            <textarea
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Введите ваш телефон..."
              className="w-full p-2 border border-gray-300 rounded mb-4"
              rows="1"
              required
              style={{ resize: 'none' }}
            />

            <label htmlFor="areaOption" className="block mb-2 text-gray-700">
              Площадь помещения:
            </label>
            <select
              id="areaOption"
              value={selectedArea}
              onChange={(e) => setSelectedArea(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-4"
              required
            >
              <option value="" disabled>Выберите...</option>
              <option value="20-25 кв.м.">20-25 кв.м.</option>
              <option value="25-50 кв.м.">25-50 кв.м.</option>
              <option value="50-60 кв.м.">50-60 кв.м.</option>
              <option value="65-80 кв.м.">65-80 кв.м.</option>
              <option value="более 80 кв.м.">более 80 кв.м.</option>
            </select>

            <label htmlFor="roomTypeOption" className="block mb-2 text-gray-700">
              Тип помещения:
            </label>
            <select
              id="roomTypeOption"
              value={selectedRoomType}
              onChange={(e) => setSelectedRoomType(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mb-4"
              required
            >
              <option value="" disabled>Выберите...</option>
              <option value="Квартира">Квартира</option>
              <option value="Офис">Офис</option>
              <option value="Цех">Цех</option>
              <option value="Конференц-зал">Конференц-зал</option>
              <option value="Склад">Склад</option>
              <option value="Другое">Другое</option>
            </select>

            <button
              type="submit"
              className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
            >
              Отправить
            </button>

            {submitted && (
              <p className="mt-4 text-green-600 whitespace-pre-line font-bold text-center">
                {`Ваш ответ был отправлен!\nМы свяжемся с Вами в самое ближайшее время.`}
              </p>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};
