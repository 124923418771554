import React from "react";

export const NavItem = ({ color, className, text, href, onClick = null }) => {
  return (
    <a 
      className={`${color} ${className} hover:text-medium-gray`} 
      onClick={onClick} 
      href={href}
    >
      {text}
    </a>
  );
};

